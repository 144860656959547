<template>
  <div id="Step5" class="p-3">
    <h3 class="title  mb-3">
      {{ t("Please adjust to your liking?") }}
    </h3>
    <div class="field">
      <div class="control p-3">
        <!-- <Datepicker
          :minDate="new Date()"
          :enableTimePicker="false"
          inline
          autoApply
          v-model="date"
        /> -->
      </div>
    </div>
    <table class="custom__table mb-0">
      <thead>
        <th>
          {{ t("Date") }}
        </th>
        <th class="has-text-right">
          {{ t("amount") }}
        </th>

        <!--        <th class="has-text-right"></th>-->
      </thead>
      <tbody>
        <tr
          v-for="({ amount, scheduled_at }, index) in adjustedPayments"
          :key="amount + scheduled_at ?? index"
        >
          <td>{{ scheduled_at }}</td>
          <td class="has-text-right">
            <input
              step=".1"
              class="input"
              type="number"
              v-model="adjustedPayments[index].amount"
            />
          </td>
          <!--          <td class="has-text-right">-->
          <!--            <span-->
          <!--              class="button is-danger "-->
          <!--              @click="adjustedPayments.splice(index, 1)"-->
          <!--            >-->
          <!--              <span class="icon is-small"-->
          <!--                ><i :class="['fas', 'fa-trash']"></i-->
          <!--              ></span>-->
          <!--            </span>-->
          <!--          </td>-->
        </tr>
        <!--        <tr>-->
        <!--          <td colspan="50">-->
        <!--            <div class="button is-fullwidth is-success">-->
        <!--              <span class="icon is-small"-->
        <!--                ><i :class="['fas', 'plus']"></i-->
        <!--              ></span>-->
        <!--            </div>-->
        <!--          </td>-->
        <!--        </tr>-->
        <tr>
          <td><b>Total:</b></td>
          <td class="has-text-right">{{ getFormattedNumber(payment_plant_amount) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
// import Datepicker from "vue3-date-time-picker";
export default {
  name: "Step5",
  components: {
    // Datepicker
  },
  props: {
    payments: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      adjustedPayments: [
        {
          amount: 2,
          scheduled_at: this.getFormattedDate(
            moment().add({ days: Math.random() * 100 })
          ),
        },
      ],
      t: this.$store.getters["Translations/getTranslations"](
        this.$parent.$options.name + this.$options.name
      ),
    };
  },
  computed: {
    payment_plant_amount() {
      return this.payments
        .map((payment) => {
          return payment.amount;
        })
        .reduce((accumulator, amount) => accumulator + +amount, 0)
        .toFixed(2);
    },
  },
  watch: {
    payments() {
      this.adjustedPayments = this.payments.map((date) => {
        date.scheduled_at = this.getFormattedDate(date.scheduled_at);
        return date;
      });
    },
  },
  methods: {
    getFormattedNumber(number) {
      return this.$store.getters[`Formatting/getFormattedNumber`](number, this.$parent.payment_plan_currency);
    },
    getFormattedDate(date) {
      return this.$store.getters[`Formatting/getFormattedDate`](date);
    },
  },
};
</script>

<style></style>
