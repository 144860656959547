<template>
  <div id="Step2">
    <h3 class="title p-3 mb-1">
      {{ t("When should this payment plan start?") }}
    </h3>
    <div class="field">
      <div class="control p-3">
        <Datepicker
          :minDate="new Date()"
          :enableTimePicker="false"
          inline
          autoApply
          v-model="date"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import ItemsTable from "./ItemsTable.vue";
import Datepicker from "vue3-date-time-picker";

export default {
  name: "Step2",
  components: {
    // ItemsTable,
    Datepicker,
  },
  data() {
    return {
      date: new Date(),

      t: this.$store.getters["Translations/getTranslations"](
        this.$parent.$options.name + this.$options.name
      ),
    };
  },

  watch: {
    date() {
      this.$emit("update:date", this.date);
    },
  },
};
</script>

<style></style>
