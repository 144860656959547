<template>
  <div id="RaisePaymentPlan">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ t("Raise Payment Plan") }}
          </p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body content mb-0 p-0">
          <Step1 ref="Step1" v-show="currentStep === 1" />

          <Step2
            ref="Step2"
            @update:date="(d) => (startingDate = d)"
            v-show="currentStep === 2"
          />

          <Step3
            @update:frequency="(f) => (frequency = f)"
            ref="Step3"
            v-show="currentStep === 3"
          />
          <Step4
            @update:amountOfPayments="(p) => (amountOfPayments = p)"
            ref="Step4"
            v-show="currentStep === 4"
          />
          <Step5
            @update:payments="(p) => (adjustedPayments = p)"
            :payments="payments"
            ref="Step5"
            v-show="currentStep === 5"
          />
        </section>
        <footer class="modal-card-foot p-0">
          <span
            class="card-footer-item has-text-danger is-clickable"
            @click="back"
          >
            {{ currentStep === 1 ? t("Cancel") : t("Back") }}
          </span>
          <span
            class="card-footer-item has-text-info is-clickable"
            @click="next"
          >
            {{ isLastStep ? t("Send") : t("Next") }}
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.dp__menu {
  width: 100%;

  .dp__calendar {
    width: 100%;

    .dp__selection_preview {
      font-size: 1.2em;
    }
  }
}
</style>

<script>
// import Datepicker from "vue3-date-time-picker";
import { useToast } from "vue-toastification";

import moment from "moment";

import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import Step4 from "./Step4.vue";
import Step5 from "./Step5.vue";

export default {
  name: "RaisePaymentPlan",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    // Datepicker,
  },
  data() {
    return {
      isOpen: false,

      currentStep: 1,

      owner_supplier: null,
      owner_buyer: null,
      payment_plan_currency: null,

      totalAmount: 0,

      discription: "",

      startingDate: new Date(),

      frequency: { days: 1, be: "1d" },

      amountOfPayments: 2,

      items: {},
      itemAmounts: {},

      adjustedPayments: [{ amount: 28, scheduled_at: new Date() }],

      loading: false,

      t: this.$store.getters["Translations/getTranslations"](
        this.$options.name
      ),
    };
  },
  computed: {
    disputed_amount() {
      return Object.values(this.items)
        .reduce((accumulator, amount) => accumulator + +amount, 0)
        .toFixed(2);
    },
    original_disputed_amount() {
      return Object.values(this.originalItems)
        .reduce((accumulator, amount) => accumulator + +amount, 0)
        .toFixed(2);
    },

    payments() {
      let {
        totalAmount,
        startingDate,
        frequency,
        amountOfPayments,
        // items,
      } = this;

      let [[fo, fr]] = Object.entries(frequency);

      let result = [];

      startingDate = moment(startingDate);
      let rest = 0;
      for (let i = 0; i < amountOfPayments; i++) {
        let j = {};
        j[fo] = fr * (i + 1);

        let amount = 0;
        let amountCeiled =
          Math.ceil((totalAmount / amountOfPayments) * 100) / 100;

        let amountFloored =
          Math.floor((totalAmount / amountOfPayments) * 100) / 100;
        if (i === amountOfPayments - 1) {
          amount = amountFloored + rest + (amountCeiled - amountFloored);
        } else {
          amount = amountFloored;
          rest += amountCeiled - amountFloored;
        }

        result.push({
          amount: amount.toFixed(2),
          scheduled_at: this.getFormattedDate(moment(startingDate).add(j)),
        });
      }

      return result;
    },
    payload() {
      const { owner_supplier, owner_buyer, items, frequency } = this;
      return {
        owner_supplier,
        owner_buyer,
        frequency,
        payments: [
          { scheduled_at: "2021-10-22", amount: 20 },
          { scheduled_at: "2021-10-23", amount: 20 },
          { scheduled_at: "2021-10-24", amount: 20 },
        ],
        items: Object.keys(items),
      };
    },

    isLastStep() {
      return this.currentStep === 5;
    },
  },
  async created() {
    this.$toast = useToast();
  },
  methods: {
    log(...values) {
      console.log(values);
    },
    open(values) {
      this.owner_supplier = values.owner_supplier;
      this.owner_buyer = values.owner_buyer;
      this.payment_plan_currency = values.payment_plan_currency;
      this.selectedRows = values.selectedRows;
      this.items = {};
      this.originalItems = {};
      values.selectedRows.forEach((row) => {
        this.items[row.invoice] = row.invoice_amount;
      });

      this.selectedItem = Object.keys(this.items)[0];

      this.totalAmount = Object.values(this.items).reduce(
        (tot, cur) => tot + cur,
        0
      );
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.currentStep = 1;
    },

    back() {
      if (this.currentStep === 1) {
        this.close();
        return;
      }
      this.currentStep--;
    },

    next() {
      if (this.isLastStep) {
        this.send();
        return;
      }

      this.currentStep++;
    },

    async send() {
      const { owner_supplier, owner_buyer, items, frequency, payments } = this;

      try {
        this.loading = true;
        let data = await this.$store.dispatch("financialKit/putPaymentPlan", {
          owner_supplier,
          owner_buyer,
          promise_amount: Object.values(items).reduce(
            (accumulator, current) => accumulator + current,
            0
          ),
          // promise_at: date.toISOString().split("T")[0],
          items: Object.keys(items),
          frequency: frequency.be,
          payments: payments,
        });

        await this.$store.dispatch("emailKit/postRaw", {
          contents: this.t(`A new payment plan was created for the debtor %debtor%!`, {
            debtor: this.owner_buyer,
          }),
          email: {
            subject: "New Payment Plan!",
            to: "joao@konnectkit.nl",
            from: "no-reply@konnectkit.nl",
          }
        });


        if (!data.success) {
          throw data;
        }
        this.$toast.success(
          `payment promise "${data.promise.name}" was created`
        );

        this.close();
      } catch (error) {
        if (error?.errors?.universal) {
          error.errors.universal.reverse().forEach((err) => {
            this.$toast.error(err, {});
          });
        }
      }
      this.$parent.emptySelectedRows();
      this.$parent.getInvoices(this.$parent.invoices.pageData.currentPage);
      this.close();
      this.loading = false;
    },
    previewFormatter(date = new Date()) {
      return date.toLocaleDateString();
    },
    getFormattedDate(date) {
      return this.$store.getters[`Formatting/getFormattedDate`](date);
    },
  },
};
</script>
