<template>
  <div id="RaisePaymentPromise">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ t("Raise Payment Promise") }}
          </p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body content mb-0 p-0">
          <div class="field m-0">
            <div class="control p-3">
              <Datepicker
                :enableTimePicker="false"
                inline
                autoApply
                v-model="date"
              />
            </div>
          </div>
          <!-- <div class="field m-0">
            <div class="control p-3">
              <textarea
                v-model="discription"
                rows="3"
                class="textarea"
                placeholder="description"
              />
            </div>
          </div> -->
          <table class=" mb-0">
            <thead>
              <th>
                {{ t("Id") }}
              </th>
              <th class="has-text-right">
                {{ t("Amount") }}
              </th>
            </thead>
            <tbody>
              <tr v-for="(amount, id) in items" :key="amount + id">
                <td>{{ id }}</td>
                <td class="has-text-right">
                  {{ getFormattedNumber(amount) }}
                </td>
              </tr>
            </tbody>
          </table>
          <hr class="mt-0" />
        </section>
        <footer class="modal-card-foot p-0">
          <span
            class="card-footer-item has-text-danger is-clickable"
            @click="close"
          >
            {{ t("Cancel") }}
          </span>
          <span
            class="card-footer-item has-text-info is-clickable"
            @click="send"
          >
            {{ t("Confirm") }}
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.dp__menu {
  width: 100%;

  .dp__calendar {
    width: 100%;

    .dp__selection_preview {
      font-size: 1.2em;
    }
  }
}
</style>

<script>
import Datepicker from "vue3-date-time-picker";
import { useToast } from "vue-toastification";

export default {
  name: "RaisePaymentPromise",
  components: {
    Datepicker,
  },
  data() {
    return {
      isOpen: false,

      owner_supplier: null,
      owner_buyer: null,
      payment_promise_currency: null,
      date: new Date(),
      discription: "",

      items: {},
      originalItems: {},

      loading: false,

      t: this.$store.getters["Translations/getTranslations"](
        this.$options.name
      ),
    };
  },
  computed: {
    disputed_amount() {
      return Object.values(this.items)
        .reduce((accumulator, amount) => accumulator + +amount, 0)
        .toFixed(2);
    },
    original_disputed_amount() {
      return Object.values(this.originalItems)
        .reduce((accumulator, amount) => accumulator + +amount, 0)
        .toFixed(2);
    },
  },
  mounted() {
    this.$toast = useToast();
  },
  methods: {
    open(values) {
      this.owner_supplier = values.owner_supplier;
      this.owner_buyer = values.owner_buyer;
      this.payment_promise_currency = values.payment_promise_currency;
      this.selectedRows = values.selectedRows;
      this.items = {};
      this.originalItems = {};
      values.selectedRows.forEach((row) => {
        this.items[row.invoice] = row.invoice_amount;
        this.originalItems[row.invoice] = row.invoice_amount;
      });

      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    async send() {
      const { owner_supplier, owner_buyer, items, date = new Date() } = this;

      try {
        this.loading = true;
        let data = await this.$store.dispatch(
          "financialKit/putPaymentPromise",
          {
            owner_supplier,
            owner_buyer,
            promise_amount: Object.values(items).reduce(
              (accumulator, current) => accumulator + current,
              0
            ),
            promise_at: date.toISOString().split("T")[0],
            items: Object.keys(items),
          }
        );

        if (!data.success) {
          throw data;
        }
        this.$toast.success(
          `payment promise "${data.promise.name}" was created`
        );

        this.close();
      } catch (error) {
        if (error?.errors?.universal) {
          error.errors.universal.reverse().forEach((err) => {
            this.$toast.error(err, {});
          });
        }
      }
      this.$parent.emptySelectedRows();
      this.$parent.getInvoices(this.$parent.invoices.pageData.currentPage);
      this.close();
      this.loading = false;
    },
    getFormattedNumber(number) {
      return this.$store.getters[`Formatting/getFormattedNumber`](number, this.payment_promise_currency);
    },
    previewFormatter(date = new Date()) {
      return date.toLocaleDateString();
    },
  },
};
</script>
