<template>
  <div class="box is-shadowless">
    <ShareInvoices ref="ShareInvoicesModal" />
    <RaisePaymentPlan ref="RaisePaymentPlanModal" />
    <RaisePaymentPromise ref="RaisePaymentPromiseModal" />
    <RaiseDispute ref="RaiseDisputeModal" />
    <import ref="ImportModal" />

    <button class="button is-small is-block mb-5 ml-3 is-pulled-right has-text-left" :class="{
      'is-primary': invoices.openInvoices === false
    }" :disabled="invoices.loading" @click="selectOpenClosedInvoices(false)">
      <span class="icon">
        <span :class="['fa', {'fa-circle' : invoices.openInvoices !== false, 'fa-check-circle' : invoices.openInvoices === false}]"></span>
      </span>
      <span class="has-text-weight-bold">{{ t('Closed') }}</span>
    </button>
    <button :disabled="invoices.loading" class="button is-small is-block mb-5 ml-3 is-pulled-right has-text-left" :class="{
    'is-primary': invoices.openInvoices === true
    }" @click="selectOpenClosedInvoices(true)">
      <span class="icon">
        <span :class="['fa', {'fa-circle' : invoices.openInvoices !== true, 'fa-check-circle' : invoices.openInvoices === true}]"></span>
      </span>
      <span class="has-text-weight-bold">{{ t('Open') }}</span>
    </button>

    <!--<button
      class="button is-small is-block mb-5 ml-3 is-pulled-right has-text-left"
      :disabled="invoices.exporting"
      @click="exportReport"
    >
      <span class="icon">
        <span class="fas fa-download"></span>
      </span>
      <span>
        {{ t("Download Customer's Invoices") }}
      </span>
    </button>
    <button
      class="button is-small is-block mb-5 is-pulled-right has-text-left"
      @click="openImportModal('invoice_invoice')"
    >
      <span class="icon">
        <span class="fas fa-upload"></span>
      </span>
      <span>
        {{ t("Import Customer's Invoices") }}
      </span>
    </button>-->
    <h3 class="title  mb-3" style="word-break: normal">
      {{ t("Invoices") }}
    </h3>

    <div class="is-clearfix"></div>

    <div class="buttons is-justify-content-space-evenly mt-5">
      <button
        class="button is-info is-light is-rounded is-small"
        @click="openShareInvoicesModal"
        :disabled="!invoices.selectedRowsActionLimited[0] && !invoices.selectedRows[0]"
      >
        <span class="icon is-pulled-right">
          <span class="fas fa-share"></span>
        </span>
        <label>{{ t("share invoices") }}</label>
      </button>
      <button
        class="button is-info is-light is-rounded is-small"
        @click="openRaisePaymentPlanModal"
        :disabled="!invoices.selectedRows[0]"
      >
        <span class="icon is-pulled-right">
          <span class="fas fa-pallet"></span>
        </span>
        <label>{{ t("raise payment plan") }} </label>
      </button>
      <button
        class="button is-info is-light is-rounded is-small"
        @click="openRaisePaymentPromiseModal"
        :disabled="!invoices.selectedRows[0]"
      >
        <span class="icon is-pulled-right">
          <span class="fas fa-hands-helping"></span>
        </span>
        <label>{{ t("raise payment promise") }} </label>
      </button>
      <button
        class="button is-info is-light is-rounded is-small"
        @click="openRaiseDisputeModal"
        :disabled="!invoices.selectedRows[0]"
      >
        <span class="icon is-pulled-right">
          <span class="fas fa-balance-scale-right"></span>
        </span>
        <label>{{ t("raise dispute") }} </label>
        <!-- 
          {
            "owner_supplier": "INCAZE",
            "owner_buyer": "BTBH:1010",
            "dispute_currency": "GBP",
            "disputed_amount": 20.53,
            "items": {
              "INV34" : 20.53
            }
          }
         -->
      </button>
    </div>
    <Table
      ref="table"
      :class="['invoices-table']"
      :rowClickHandler="selectRow"
      :loading="invoices.loading"
      @newPage="getInvoices"
      :pageData="invoices.pageData"
      :headers="invoices.headers"
      :rows="invoices.rows"
      :breakingPoint="400"
    />
  </div>
</template>

<script>
import Table from "../../../components/Dynamic/Table.vue";

import { mapActions } from "vuex";

import ShareInvoices from "./Modals/ShareInvoices";
import RaisePaymentPlan from "./Modals/RaisePaymentPlan/index";
import RaisePaymentPromise from "./Modals/RaisePaymentPromise";
import RaiseDispute from "./Modals/RaiseDispute";
import Import from "@/views/Settings/Modals/Import";
import { useToast } from "vue-toastification";

export default {
  name: "InvoicesTable",
  components: {
    Import,
    Table,
    ShareInvoices,
    RaisePaymentPromise,
    RaisePaymentPlan,
    RaiseDispute,
  },
  props: {
    customer: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      invoices: {
        openInvoices: true,
        loading: false,
        exporting: false,
        pageData: {
          currentPage: 1,
          lastPage: 1,
        },
        headers: this.$store.getters["TableHeaders/getHeaders"](
          "cm_incaze_nl_all_invoices"
        ),
        rows: [],
        selectedRows: [],
        selectedRowsActionLimited: [],
      },
      t: this.$store.getters["Translations/getTranslations"](
        this.$options.name
      ),
    };
  },
  created() {
    this.getInvoices();
  },
  mounted() {
    this.$toast = useToast();
    this.$store.state.emitter.$on("reloadCustomerInvoices", () => {
      this.getInvoices(this.invoices.pageData.currentPage);
    });
    this.$store.state.emitter.$on("importCompleted", () => {
      this.getInvoices(1);
    });
  },
  methods: {
    ...mapActions([
      "dataviewKit/getViewData",
      "exportKit/postExportViewDataset",
    ]),
    selectOpenClosedInvoices(open = true) {
      this.invoices.openInvoices = open;
      this.getInvoices(1);
    },
    selectRow(gRow = {}) {
      if (!gRow.invoice) {
        return true;
      }

      if (
        gRow.dispute_identifier !== null ||
        gRow.promise_identifier !== null ||
        gRow.plan_identifier !== null
      ) {

        if (gRow.selected) {
          this.invoices.selectedRowsActionLimited = this.invoices.selectedRowsActionLimited.filter(
              (row) => row.invoice !== gRow.invoice
          );
          this.invoices.rows.find(
              (row) => row.invoice === gRow.invoice
          ).selected = false;
        } else {
          this.invoices.selectedRowsActionLimited.push(gRow);

          this.invoices.rows.find(
              (row) => row.invoice === gRow.invoice
          ).selected = true;
        }

        // this.$toast.warning(
        //   `This invoice is already being collected from a ${
        //     gRow.dispute_identifier !== null
        //       ? "Dispute"
        //       : gRow.promise_identifier
        //       ? "Payment Plan"
        //       : "Payment Promise"
        //   }.`
        // );
        return false;
      }

      if (gRow.selected) {
        this.invoices.selectedRows = this.invoices.selectedRows.filter(
          (row) => row.invoice !== gRow.invoice
        );
        this.invoices.rows.find(
          (row) => row.invoice === gRow.invoice
        ).selected = false;
      } else {
        this.invoices.selectedRows.push(gRow);

        this.invoices.rows.find(
          (row) => row.invoice === gRow.invoice
        ).selected = true;
      }
    },
    emptySelectedRows() {
      this.invoices.rows = this.invoices.rows.map((row) => ({
        ...row,
        selected: false,
      }));
      this.invoices.selectedRows = [];
      this.invoices.selectedRowsActionLimited = [];
      this.$forceUpdate();
    },
    getInvoices(page = 1) {
      this.invoices.loading = true;
      this["dataviewKit/getViewData"](
        {
          view: "cm_incaze_nl_all_invoices",
          page: page,
          itemsPerPage: 10,
          parameters: {
            owner_identifier: (
              this.$store.state.me.activeSeller ??
              this.$store.state.me.user.companies[0] ?? { identifier: "" }
            ).identifier,
            company_id: this.$route.params.company_id,
          },
          filters: {
            is_paid: this.invoices.openInvoices ? 0 : 1,
          },
        },
        { "content-type": "application/json" }
      )
        .then((data) => {
          this.invoices.loading = false;
          this.invoices.rows = data.data;
          this.invoices.pageData = {
            currentPage: data.current_page,
            lastPage: data.last_page,
          };
          this.invoices.rows = this.invoices.rows.map((gRow) => {
            let selected = this.invoices.selectedRows.find(
              (row) => row.invoice === gRow.invoice
            );
            return { ...gRow, selected };
          });
        })
        .catch((data) => data);
    },
    exportReport() {
      this.invoices.exporting = true;
      this["exportKit/postExportViewDataset"](
        {
          view: "cm_incaze_nl_all_invoices",
          paginated: false,
          parameters: {
            owner_identifier: (
              this.$store.state.me.activeSeller ??
              this.$store.state.me.user.companies[0] ?? { identifier: "" }
            ).identifier,
            company_id: this.$route.params.company_id,
          },
          the_headers: this.invoices.headers.map((item) => {
            return item.name;
          }),
        },
        { "content-type": "application/json" }
      )
        .then((data) => {
          if (data.success) {
            var a = document.createElement("a");
            a.href = data.file.url;
            a.target = "_blank";
            a.download = `${this.$route.params.id}.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            this.$toast.error("Something went wrong", {});
          }
          this.invoices.exporting = false;
        })
        .catch((data) => {
          console.error(data);
          this.$toast.error(data, {});

          this.invoices.exporting = false;
        });
    },

    openRaiseDisputeModal() {
      const {
        owner_identifier,
        company_id,
        currency,
      } = this.invoices.selectedRows[0];
      this.$refs.RaiseDisputeModal.open({
        owner_supplier: owner_identifier,
        owner_buyer: company_id,
        dispute_currency: currency,

        selectedRows: this.invoices.selectedRows,
      });
      this.$nextTick(() => {
        this.emptySelectedRows();
      });
    },
    openShareInvoicesModal() {
      let selectedRows = (this.invoices.selectedRowsActionLimited.concat(this.invoices.selectedRows));
      const {
        owner_identifier,
        company_id,
        currency,
      } = selectedRows[0];
      this.$refs.ShareInvoicesModal.open({
        selectedRows: selectedRows,
        owner_supplier: owner_identifier,
        owner_buyer: company_id,
        invoice_currency: currency,
        default_email: this.customer.primary_email ?? '',
      });
      this.$nextTick(() => {
        this.emptySelectedRows();
      });
    },
    openRaisePaymentPlanModal() {
      const { owner_identifier, company_id, currency } = this.invoices.selectedRows[0];

      this.$refs.RaisePaymentPlanModal.open({
        owner_supplier: owner_identifier,
        owner_buyer: company_id,
        payment_plan_currency: currency,
        selectedRows: this.invoices.selectedRows,
      });
      this.$nextTick(() => {
        this.emptySelectedRows();
      });
    },
    openRaisePaymentPromiseModal() {
      const { owner_identifier, company_id, currency } = this.invoices.selectedRows[0];
      this.$refs.RaisePaymentPromiseModal.open({
        owner_supplier: owner_identifier,
        owner_buyer: company_id,
        payment_promise_currency: currency,
        selectedRows: this.invoices.selectedRows,
      });
      this.$nextTick(() => {
        this.emptySelectedRows();
      });
    },
    openImportModal(objectType) {
      this.$refs.ImportModal.open({
        owner_identifier: (
          this.$store.state.me.activeSeller ??
          this.$store.state.me.user.companies[0] ?? { identifier: "" }
        ).identifier,
        objectType: objectType,
      });
      this.$nextTick(() => {
        this.emptySelectedRows();
      });
    },
  },
};
</script>
