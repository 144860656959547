<template>
  <div class="CustomerCustomersTable" v-if="rows.length > 0">
    <import ref="ImportModal" />
    <div class="box is-shadowless">
      <h3 class="title  mb-3">
        {{ t("Customers") }}
        <button
          class="button is-pulled-right is-small"
          :disabled="isExporting"
          @click="exportReport"
        >
          <span class="icon">
            <span
              :class="[
                'fas',
                {
                  'fa-file-excel': !isExporting,
                  'fa-spin fa-spinner': isExporting,
                },
              ]"
            ></span>
          </span>
          <span>{{ t("Download Customer List") }}</span>
        </button>
        <button
          class="button is-pulled-right mr-3 is-small"
          @click="openImportModal('organizational_company')"
        >
          <span class="icon">
            <span
              :class="[
                'fas',
                {
                  'fa-file-excel': !isExporting,
                  'fa-spin fa-spinner': isExporting,
                },
              ]"
            ></span>
          </span>
          <span>{{ t("Import Customers") }}</span>
        </button>
      </h3>
      <Table
        :rowClickHandler="
          (row = {}) => {
            if (!row.company_id) return true;
            $router.push({
              name: 'Customer',
              params: { id: row.company_id, ...row },
            });
          }
        "
        :loading="loading"
        @newPage="getNewPage"
        :pageData="pageData"
        :headers="headers"
        :rows="rows"
        :breakingPoint="600"
      />
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

import Table from "../../../components/Dynamic/Table.vue";

import { mapActions } from "vuex";
import Import from "@/views/Settings/Modals/Import";

export default {
  name: "CustomerCustomersTable",
  components: { Import, Table },
  data() {
    return {
      loaded: false,
      isExporting: false,
      loading: false,
      pageData: {
        currentPage: 1,
        lastPage: 1,
      },
      headers: this.$store.getters["TableHeaders/getHeaders"](
        "cm_incaze_nl_all_customers"
      ),
      rows: [],
      t: this.$store.getters["Translations/getTranslations"](
        this.$options.name
      ),
    };
  },
  watch: {
    "$store.state.me.activeSeller"() {
      this.getNewPage();
      this.rows = [];
    },
  },

  mounted() {
    this.getNewPage();
    this.$toast = useToast();
    setTimeout(() => (this.loaded = true), 2000);
  },
  methods: {
    ...mapActions([
      "dataviewKit/getViewData",
      "exportKit/postExportViewDataset",
    ]),
    exportReport() {
      this.isExporting = true;
      this["exportKit/postExportViewDataset"](
        {
          view: (
              this.$store.state.me.activeSeller ??
              this.$store.state.me.user.companies[0] ?? { identifier: "" }
          ).identifier === 'ICMS' ? "cm_incaze_nl_all_customers_ICMS" : "cm_incaze_nl_all_customers",          paginated: false,
          parameters: {
            owner_identifier: this.$route.params.company_id,
            parent_identifier: (
              this.$store.state.me.activeSeller ??
              this.$store.state.me.user.companies[0] ?? { identifier: "" }
            ).identifier,
          },
          the_headers: this.headers.map((item) => {
            return item.name;
          }),
        },
        { "content-type": "application/json" }
      )
        .then((data) => {
          if (data.success) {
            var a = document.createElement("a");
            a.href = data.file.url;
            a.target = "_blank";
            a.download = `${this.$route.params.id}.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            this.$toast.error("Something went wrong", {});
          }
          this.isExporting = false;
        })
        .catch((data) => {
          console.error(data);
          this.$toast.error(data, {});

          this.isExporting = false;
        });
    },
    getNewPage(page = 1) {
      this.loading = true;
      this["dataviewKit/getViewData"](
        {
          view: (
              this.$store.state.me.activeSeller ??
              this.$store.state.me.user.companies[0] ?? { identifier: "" }
          ).identifier === 'ICMS' ? "cm_incaze_nl_all_customers_ICMS" : "cm_incaze_nl_all_customers",          page: page,
          parameters: {
            owner_identifier: this.$route.params.company_id,
            parent_identifier: (
              this.$store.state.me.activeSeller ??
              this.$store.state.me.user.companies[0] ?? { identifier: "" }
            ).identifier,
          },
        },
        { "content-type": "application/json" }
      )
        .then((data) => {
          this.pageData = {
            currentPage: data.current_page,
            lastPage: data.last_page,
          };
          this.rows = data.data;
          this.loading = false;
        })
        .catch((data) => {
          console.error(data);
          this.$toast.error(data, {});
          this.loading = false;
        });
    },
    openImportModal(objectType) {
      this.$refs.ImportModal.open({
        owner_identifier: this.$route.params.company_id,
        objectType: objectType,
      });
    },
  },
};
</script>
