<template>
  <div id="RaiseDispute">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ t("Share invoices") }}
          </p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body content mb-0 p-0">
          <div class="field m-0">
            <div class="control p-3">
              <label for="">TO:</label>
              <input
                v-model="email"
                type="text"
                class="input"
                :placeholder="t('email')"
              />
            </div>
          </div>
          <div class="field m-0">
            <div class="control p-3">
              <label for="">FROM:</label>
              <input
                v-model="from"
                type="text"
                class="input"
                :placeholder="t('email from')"
              />
            </div>
          </div>
          <table class=" mb-0">
            <thead>
              <th>
                {{ t("Id") }}
              </th>
              <th class="has-text-right">
                {{ t("Original amount") }}
              </th>
            </thead>
            <tbody>
              <tr v-for="(amount, id) in items" :key="amount + id">
                <td>{{ id }}</td>
                <td class="has-text-right">
                  {{ getFormattedNumber(amount) }}
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <footer class="modal-card-foot p-0">
          <span
            class="card-footer-item has-text-danger is-clickable"
            @click="close"
          >
            {{ t("Cancel") }}
          </span>
          <span
            class="card-footer-item has-text-info is-clickable"
            @click="send"
          >
            {{ t("Confirm") }}
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

export default {
  name: "ShareInvoices",
  data() {
    return {
      isOpen: false,

      email: "",
      from: '',
      items: {},
      owner_supplier: null,
      owner_buyer: null,
      invoice_currency: null,
      loading: false,

      t: this.$store.getters["Translations/getTranslations"](
        this.$options.name
      ),
      language: this.$store.getters["Translations/getCurrentLanguage"](),
    };
  },
  computed: {
    disputed_amount() {
      return Object.values(this.items)
        .reduce((accumulator, amount) => accumulator + +amount, 0)
        .toFixed(2);
    },
    original_disputed_amount() {
      return Object.values(this.originalItems)
        .reduce((accumulator, amount) => accumulator + +amount, 0)
        .toFixed(2);
    },
  },
  mounted() {
    this.$toast = useToast();
  },
  methods: {
    open(values) {
      this.items = {};
      this.originalItems = {};
      values.selectedRows.forEach((row) => {
        this.items[row.invoice] = row.invoice_amount;
      });
      this.owner_supplier = values.owner_supplier;
      this.owner_buyer = values.owner_buyer;
      this.invoice_currency = values.invoice_currency;
      this.email = values.default_email;
      this.from = this.$store.state.me.user.email;
      this.isOpen = true;
    },
    close() {
      this.email = "";
      this.items = {};
      this.isOpen = false;
    },
    async send() {
      const { email, from, items, owner_supplier, owner_buyer } = this;

      try {
        // eslint-disable-next-line no-control-regex
        if (!new RegExp("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]+").test(email))
          throw { errors: { universal: ["email not valid"] } };
        this.loading = true;
        let data = await this.$store.dispatch(
          "invoiceKit/postSendInvoicesByEmail",
          {
            email: {
              to: email,
              from: from,
              subject: this.t('Requested invoices'),
              template: `cm.incaze.nl.share.invoices`
            },
            owner_supplier: owner_supplier,
            owner_buyer: owner_buyer,
            invoices: Object.keys(items),
          }
        );

        if (!data.success) {
          throw data;
        }
        this.$toast.success(this.t(`invoices succesfully shared`));

        this.close();
      } catch (error) {
        console.error(error);
        if (error?.errors?.universal) {
          error.errors.universal.reverse().forEach((err) => {
            this.$toast.error(err, {});
          });
        }
      }
      this.$parent.emptySelectedRows();

      this.close();
      this.loading = false;
    },
    getFormattedNumber(number) {
      return this.$store.getters[`Formatting/getFormattedNumber`](number, this.invoice_currency);
    },
  },
};
</script>
