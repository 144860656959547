<template>
  <div id="Step1">
    <h3 class="title p-3 mb-1">
      {{ t("Please confirm these are the invoices") }}
    </h3>
    <ItemsTable :items="$parent.items" />
  </div>
</template>

<script>
import ItemsTable from "./ItemsTable.vue";

export default {
  name: "Step1",
  components: {
    ItemsTable,
  },
  data() {
    return {
      t: this.$store.getters["Translations/getTranslations"](
        this.$parent.$options.name + this.$options.name
      ),
    };
  },
};
</script>

<style></style>
