<template>
  <div id="Step4" class="p-3">
    <h3 class="title  mb-3">
      {{ t("How many payments should this plan have?") }}
    </h3>
    <div class="field has-addons">
      <p class="control">
        <button
          class="button"
          :disabled="amountOfPayments - 1 < 2"
          @click="amountOfPayments--"
        >
          -
        </button>
      </p>
      <p class="control is-expanded">
        <input
          class="input"
          type="number"
          min="2"
          :max="max"
          v-model.number="amountOfPayments"
          @blur="checkAmountOfPayments()"
        />
      </p>
      <p class="control">
        <button
          class="button"
          :disabled="amountOfPayments + 1 > max"
          @click="amountOfPayments++"
        >
          +
        </button>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step4",
  components: {},
  data() {
    return {
      amountOfPayments: 2,
      t: this.$store.getters["Translations/getTranslations"](
        this.$parent.$options.name + this.$options.name
      ),
    };
  },
  computed: {
    max() {
      return Math.ceil(this.$parent.totalAmount);
    },
  },
  watch: {
    amountOfPayments() {
      this.$emit("update:amountOfPayments", this.amountOfPayments);
    },
  },
  mounted() {
    this.availableFrequencies = {
      "1d": this.t("every day"),
      "1w": this.t("every week"),
      "2w": this.t("every other week"),
      "1m": this.t("every month"),
      "3m": this.t("every quarter"),
      "6m": this.t("every six months"),
    };

    this.frequency = Object.keys(this.availableFrequencies)[0];
  },
  methods: {
    checkAmountOfPayments() {
      let { amountOfPayments, max } = this;

      if (amountOfPayments - 1 < 2) this.amountOfPayments = 2;
      if (amountOfPayments + 1 > max) this.amountOfPayments = max;
    },
  },
};
</script>

<style></style>
