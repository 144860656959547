<template>
  <div id="SingleView" class="p-5">
    <div class="columns is-multiline">
      <!--      <div class="column is-3 is-2-desktop">-->
      <!--        <figure class="image is-square">-->
      <!--          <img :src="customerData.image_url" />-->
      <!--        </figure>-->
      <!--      </div>-->
      <GeneralSettingsModal ref="GeneralSettingsModal"/>

      <div class="column is-12 has-text-centered"  v-if="loaders.customer">
        <span>
            <span class="icon">
              <span class="fa fa-spin fa-spinner"></span>
            </span>
              <span class="menu-label">Loading Debtor...</span>
          </span>
      </div>

      <div class="column is-12 is-8-desktop pb-5">
        <span class="has-text-weight-bold has-text-grey is-flex is-size-7">
          <span
              class="
              tag
              is-default
              mr-3
              has-text-weight-bold has-text-grey
              is-flex is-size-7
            "
              v-if="Number(customerData.parent_identifier) !== 0"
          >
            {{ customerData.parent_identifier }}
          </span>
          <span class="mt-1">{{ $route.params.company_id }}</span>
        </span>
        <span class="is-flex is-size-3">
          {{ customerData.company_name }}
        </span>
        <span v-if="customerData.street_line1"
        >{{ customerData.street_line1 }}<br
        /></span>
        <span v-if="customerData.street_line2"
        >{{ customerData.street_line2 }}<br
        /></span>
        <span
        >{{ customerData.postal_code }} {{ customerData.city }}<br
        /></span>
        <span>{{ customerData.country }}</span>
        <button
            class="button is-small mt-4 is-block has-text-left"
            @click="openGeneralSettingsModal()"
        >
          <span class="icon"><span class="fas fa-cogs"></span></span>
          <span>{{ t("Change General Settings") }}</span>
        </button>
        <button
            class="button is-small mt-2 is-block has-text-left"
            v-if="$store.state.me.user.email === 'jm@konnectkit.nl' || true"
            @click="goToCreateInvoice()"
        >
          <span class="icon"><span class="fas fa-file-pdf"></span></span>
          <span>{{ t("Create an invoice") }}</span>
        </button>
      </div>

      <div
          class="
          column
          has-text-grey has-text-left-touch has-text-right has-text-weight-bold
          is-12
          is-4-desktop
          is-flex
          is-flex-direction-column
          is-inline-block-touch
          is-justify-content-space-evenly
          hide-on-mobile
        "
      >

        <span class="is-block is-size-7"
        >{{ t("VAT:") }} {{ customerData.company_vat ?? "N/A" }}</span
        >
        <span class="is-block is-size-7"
        >{{ t("Currency:") }} {{ customerData.currency }}</span
        >
        <span class="is-block is-size-7"
        >{{ t("PMT Terms:") }} {{ customerData.payment_terms }}</span
        >
        <span class="is-block is-size-7"
        >{{ t("Credit Limit:") }} {{ customerData.credit_limit ?? 0 }}</span
        >
        <span class="is-block is-size-7"
        >{{ t("Buyer ID:") }} {{ customerData.buyer_id ?? "N/A" }}</span
        >
        <span class="is-block is-size-7"
        >{{ t("Policy ID:") }} {{ customerData.policy_id ?? "N/A" }}</span
        >
        <span class="is-block is-size-7"
        >{{ t("Primary Email:") }}
          {{ customerData.primary_email ?? "N/A" }}</span
        >
        <span class="is-block is-size-7"
        >{{ t("Primary Phone:") }}
          {{ customerData.primary_phone ?? "N/A" }}</span
        >
        <span
            class="is-block is-size-7"
            v-if="customerData.last_reminder_identifier"
        >{{ t("Last reminder:") }}
          {{ customerData.last_reminder_identifier }}</span
        >
        <span class="is-block is-size-7" v-if="customerData.last_reminder_date"
        >{{ t("Last reminder date:") }}
          {{ customerData.last_reminder_date }}</span
        >
      </div>
      <div class="column is-9">
        <Tags
            :ccid="customerData.collection_case_identifier"
            :tagData="customerData.parsedMetrics"
        />
      </div>
      <div class="column is-3">
        <template v-if="customerData.owner_identifier === 'ICMS'">
          <button
              class="button is-pulled-right is-small"
              :disabled="
              loaders.closing || Number(customerData.has_collection_case) === 0
            "
              @click="closeCollectionCase"
          >
            <span v-if="!!customerData.has_collection_case">
              {{ t("Close this Collection Case") }}
            </span>
            <span v-else>{{ t("Collection Case Closed") }}</span>

            <span class="icon">
              <span
                  class="fa fa-spin fa-spinner"
                  v-if="
                  loaders.closing &&
                  Number(customerData.has_collection_case) !== 0
                "
              ></span>
              <span
                  class="fa fa-times-circle"
                  v-if="
                  !loaders.closing &&
                  Number(customerData.has_collection_case) !== 0
                "
              ></span>
              <span
                  class="fa fa-check-circle"
                  v-if="Number(customerData.has_collection_case) === 0"
              ></span>
            </span>
          </button>
        </template>
        <template v-else>
          <button
              class="button is-pulled-right is-small"
              :disabled="
              loaders.claim || Number(customerData.has_collection_case) === 1 || loaders.customer
            "
              @click="raiseCollectionClaim"
          >
            <template v-if="customerData.has_collection_case === 1">
              <span>{{ t("Has a collection case open") }}</span>
            </template>
            <template v-else>
              <span>{{ t("Raise Collection Claim") }}</span>
            </template>
            <span class="icon">
              <span
                  class="fa fa-spin fa-spinner"
                  v-if="
                  loaders.claim &&
                  Number(customerData.has_collection_case) !== 1
                "
              ></span>
              <span
                  class="fa fa-exclamation-triangle"
                  v-if="
                  !loaders.claim &&
                  Number(customerData.has_collection_case) !== 1
                "
              ></span>
              <span
                  class="fa fa-check"
                  v-if="Number(customerData.has_collection_case) === 1"
              ></span>
            </span>
          </button>
        </template>
      </div>
      <div class="column is-12">
        <InvoicesTable :customer="customerData"/>
      </div>
      <div class="column is-12">
        <CustomerCustomersTable/>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import InvoicesTable from "./InvoicesTable.vue";
import Tags from "./Tags.vue";

import {mapActions} from "vuex";
import CustomerCustomersTable from "@/views/Customers/SingleView/CustomersTable";
import GeneralSettingsModal from "@/views/Settings/Modals/GeneralSettings";

export default {
  name: "SingleView",
  components: {
    GeneralSettingsModal,
    CustomerCustomersTable,
    InvoicesTable,
    Tags
  },
  data() {
    return {
      customerData: {},
      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
      loaders: {
        claim: false,
        customer: false,
      }
    };
  },
  watch: {
    "$store.state.me.activeSeller"() {
      this.$router.go(-1);
    }
  },
  created() {
    if (this.$route.params["company_name"]) {
      this.customerData = this.$route.params;
      this.appendCustomerDataMetrics();
    } else {
      this.getCustomerData();
    }
  },
  methods: {
    ...mapActions([
      "dataviewKit/getViewData",
      "flowKit/postCmtHandleSendReminders",
      "financialKit/putCollectionCase",
      "financialKit/postUpdateCollectionCase",
      "financialKit/postCalculateCollectionCaseInterests",
      "emailKit/postRaw"
    ]),

    appendCustomerDataMetrics() {
      this.customerData.parsedMetrics = JSON.parse(
          this.customerData.metrics || "[]"
      );
      if (Number(this.customerData.has_collection_case) === 1) {
        this.customerData.parsedMetrics.push({
          key: "collection_amount",
          value: this.customerData.collection_amount,
          classes: "is-primary",
          format: "getFormattedNumber",
          editable: true
        });

        this.customerData.parsedMetrics.push({
          key: "collection_costs",
          value: this.customerData.collection_costs,
          classes: "is-primary",
          format: "getFormattedNumber",
          editable: true
        });

        this.customerData.parsedMetrics.push({
          key: "collection_interests",
          value: this.customerData.collection_interests,
          classes: "is-primary",
          format: "getFormattedNumber",
          editable: true
        });
      }
    },
    openGeneralSettingsModal() {
      this.$refs.GeneralSettingsModal.open({
        owner_identifier: this.$route.params.company_id,
        parent_identifier: (
            this.$store.state.me.activeSeller ??
            this.$store.state.me.user.companies[0] ?? {identifier: ""}
        ).identifier === "ICMS" ? this.customerData.parent_identifier : this.customerData.owner_identifier,
      });
    },
    goToCreateInvoice() {
      this.$router.push({
        name: "InvoiceCustomer",
        params: this.customerData
      });
    },
    getCustomerData(dataForce = {}) {
      this.loaders.customer = true;
      this["dataviewKit/getViewData"](
          {
            view:
                (
                    this.$store.state.me.activeSeller ??
                    this.$store.state.me.user.companies[0] ?? {identifier: ""}
                ).identifier === "ICMS"
                    ? "cm_incaze_nl_all_customers_ICMS"
                    : ((window.innerWidth <= 700)
                        ?"cm_incaze_nl_all_customers"
                        : "cm_incaze_nl_all_customers_mobile"),
            parameters: {
              owner_identifier: (
                  this.$store.state.me.activeSeller ??
                  this.$store.state.me.user.companies[0] ?? {identifier: ""}
              ).identifier,
              parent_identifier: false
            },
            first: true,
            filters: {
              company_id: this.$route.params.company_id
            }
          },
          {"content-type": "application/json"}
      ).then(data => {
        this.customerData = Object.assign(data.data, dataForce);
        this.appendCustomerDataMetrics();
        this.$route.params = {...this.$route.params, ...data.data, ...dataForce};
        this.loaders.customer = false;
      });
    },
    raiseCollectionClaim() {
      if (
          window.confirm(
              "Are you sure that would like to raise a collection claim on this customer?"
          )
      ) {
        this.loaders.claim = true;

        let payload = { };
        payload[(
            this.$store.state.me.activeSeller ??
            this.$store.state.me.user.companies[0] ?? {identifier: ""}
        ).identifier] = [{
            company: this.$route.params.company_id,
            reminder: "collection.case"
          }];

        this["flowKit/postCmtHandleSendReminders"]({
          reminders: payload
        })
            .then(() => {
              this.getCustomerData({
                has_collection_case: 1,
              });
              this.customerData.has_collection_case = 1;
              this.loaders.claim = false;
            });


        // this["financialKit/putCollectionCase"](
        //   {
        //     owner_claimant: (
        //       this.$store.state.me.activeSeller ??
        //       this.$store.state.me.user.companies[0] ?? { identifier: "" }
        //     ).identifier,
        //     owner_defendant: ,
        //     owner_prosecutor: "INCAZE",
        //     collection_amount: this.customerData.open_amount
        //   },
        //   { "content-type": "application/json" }


        //     this["financialKit/postCalculateCollectionCaseInterests"](
        //       {
        //         collection_case: data.collection_case.collection_case,
        //         since: this.customerData.oldest_overdue_invoice,
        //         rate: 8
        //       },
        //       { "content-type": "application/json" }
        //     )
        //       .then(data2 => {
        //         console.log(data, data2);
        //
        //         this["emailKit/postRaw"]({
        //           contents: this.t(
        //             `A new collection case was raised for the debtor  %debtor%!`,
        //             {
        //               debtor: this.owner_defendant
        //             }
        //           ),
        //           email: {
        //             subject: "Collection case created!",
        //             to: "joao@konnectkit.nl",
        //             from: "no-reply@konnectkit.nl"
        //           }
        //         });
        //       })
        //       .catch(error => {
        //         console.log(error);
        //         this.loaders.claim = false;
        //       });
        //   })
        //   .catch(error => {
        //     console.log(error);
        //     this.loaders.claim = false;
        //   });
      }
    },
    closeCollectionCase() {
      if (
          window.confirm(
              "Are you sure that would like to close this collection case?"
          )
      ) {
        this.loaders.closing = true;
        this["financialKit/postUpdateCollectionCase"](
            {
              collection_case: this.customerData.collection_case_id,
              closed: true
            },
            {"content-type": "application/json"}
        )
            .then(data => {
              console.log(data);
              this.customerData.has_collection_case = 0;
              this.loaders.closing = false;
            })
            .catch(error => {
              console.log(error);
              this.loaders.closing = false;
            });
      }
    }
  }
};
</script>
