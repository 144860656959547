<template>
  <div id="RaiseDispute">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ t("Raise dispute") }}
          </p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body content mb-0 p-0">
          <div class="field mb-3">
            <div class="control p-3 select is-fullwidth">
              <select name="basis" id="basis" v-model="basis" class="select">
                <option value="">{{ t('Select a reason') }}</option>
                <option value="teste reason">teste reason</option>
              </select>
            </div>
          </div>
          <div class="field m-0">
            <div class="control p-3">
              <textarea
                v-model="description"
                rows="3"
                class="textarea"
                :placeholder="t('description')"
              />
            </div>
          </div>
          <table class="custom__table mb-0">
            <thead>
              <th>
                {{ t("Id") }}
              </th>
              <th class="has-text-right">
                {{ t("Original amount") }}
              </th>
              <th class="has-text-right">
                {{ t("dispute amount") }}
              </th>
            </thead>
            <tbody>
              <tr v-for="(amount, id) in originalItems" :key="amount + id">
                <td>{{ id }}</td>
                <td class="has-text-right">
                  {{ getFormattedNumber(originalItems[id]) }}
                </td>
                <td class="has-text-right">
                  <input
                    step=".1"
                    class="input"
                    type="number"
                    :placeholder="t('Text input')"
                    :max="originalItems[id]"
                    @keyup="items[id] > originalItems[id] ? (items[id] = originalItems[id]) : ( items[id] < 0 ? (items[id] = 0) : null )"
                    :min="0"
                    v-model="items[id]"
                  />
                  <!-- {{ amount }} -->
                </td>
              </tr>

              <tr>
                <td><b>Total:</b></td>
                <td class="has-text-right">
                  {{ getFormattedNumber(original_disputed_amount) }}
                </td>
                <td class="has-text-right">
                  <input
                    class="input"
                    type="text"
                    :value="disputed_amount"
                    readonly
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <hr class="mt-0" />
        </section>
        <footer class="modal-card-foot p-0">
          <span
            class="card-footer-item has-text-danger is-clickable"
            @click="close"
          >
            {{ t("Cancel") }}
          </span>
          <span
            class="card-footer-item has-text-info is-clickable"
            @click="send"
          >
            {{ t("Confirm") }}
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<script>
import { useToast } from "vue-toastification";

export default {
  name: "RaiseDispute",
  data() {
    return {
      isOpen: false,

      owner_supplier: null,
      owner_buyer: null,
      dispute_currency: null,
      description: "",
      basis: '',
      items: {},
      originalItems: {},

      loading: false,

      t: this.$store.getters["Translations/getTranslations"](
        this.$options.name
      ),
    };
  },
  computed: {
    disputed_amount() {
      return Object.values(this.items)
        .reduce((accumulator, amount) => accumulator + +amount, 0)
        .toFixed(2);
    },
    original_disputed_amount() {
      return Object.values(this.originalItems)
        .reduce((accumulator, amount) => accumulator + +amount, 0)
        .toFixed(2);
    },
  },
  mounted() {
    this.$toast = useToast();
  },
  methods: {
    getFormattedNumber(number) {
      return this.$store.getters[`Formatting/getFormattedNumber`](number, this.dispute_currency);
    },
    open(values) {
      console.log(values);

      this.owner_supplier = values.owner_supplier;
      this.owner_buyer = values.owner_buyer;
      this.dispute_currency = values.dispute_currency;
      this.selectedRows = values.selectedRows;
      this.items = {};
      this.originalItems = {};
      values.selectedRows.forEach((row) => {
        this.items[row.invoice] = row.invoice_amount;
        this.originalItems[row.invoice] = row.invoice_amount;
      });

      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.$emit('dismiss');
    },
    async send() {
      const {
        owner_supplier,
        owner_buyer,
        dispute_currency,
        description,
        disputed_amount,
        items,
        basis,
      } = this;
      try {
        this.loading = true;
        let data = await this.$store.dispatch("financialKit/putDispute", {
          owner_supplier,
          owner_buyer,
          dispute_currency,
          description,
          disputed_amount,
          items,
          basis,
        });

        if (!data.success) {
          throw data;
        }
        this.$toast.success(`dispute "${data.dispute.name}" was created`);

        this.close();
      } catch (error) {
        if (error?.errors?.universal) {
          error.errors.universal.reverse().forEach((err) => {
            this.$toast.error(err, {});
          });
        }
      }
      this.$parent.emptySelectedRows();
      this.$parent.getInvoices(this.$parent.invoices.pageData.currentPage);
      this.close();
      this.loading = false;
    },
  },
};
</script>
