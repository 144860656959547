<template>
  <div class="column is-flex is-flex-wrap-wrap is-12 py-1">
    <div class="field is-grouped is-grouped-multiline">
      <template
          v-for="{ value, key, classes, format, currency, editable = false } in tags"
          :key="value + key"
      >
      <div v-if="value !== 'N/A'"
        class="control"
      >
        <Tag
          :key="key"
          :name="t(key)"
          :value="this[format] ? this[format](value, currency?currency:'EUR') : value"
          :rawValue="value"
          :classes="classes"
          :editable="editable"
          :ccid="ccid ?? ''"
        />
      </div>
      </template>
    </div>
  </div>
</template>

<script>
import Tag from "@/components/Dynamic/Tag.vue";

export default {
  name: "SingleViewTags",
  components: {
    Tag
  },
  data() {
    return {
      t: this.$store.getters["Translations/getTranslations"](this.$options.name)
    };
  },
  props: {
    tagData: {
      required: false,
      type: Array,
      default() {
        return [
          {
            key: "open_amount",
            value: 88,
            classes: "",
            transformer: "money:EUR",
            currency: 'EUR',
          }
        ];
      }
    },
    ccid: {
      required: false,
      default() {
        return "";
      }
    }
  },
  computed: {
    tags() {
      if (this.tagData) {
        return this.tagData.map(tag => {
          tag.classes = tag.classes || "is-info";
          if (!tag.transformer) return tag;
          return {
            ...tag,
            value: this.formatter(
              tag.transformer.split(":")[0],
              tag.value,
              ...tag.transformer.split(":")[1].split(",")
            )
          };
        });
      } else {
        return [];
      }
    }
  },
  methods: {
    getFormattedNumber(number, currency = 'EUR') {
      return this.$store.getters[`Formatting/getFormattedNumber`](number, currency);
    },
    formatter(type, value, ...params) {
      let formatters = {
        money: (value, currency) => {
          let currencies = { EUR: "€", USD: "$", SEK: 'SEK' };
          value = value.toFixed(2).replace(".", ",");
          return currencies[currency]
            ? `${currencies[currency]} ${value}`
            : "currency doesn't exist";
        }
      };

      if (Object.keys(formatters).includes(type)) {
        return formatters[type](value, ...params);
      } else {
        return value;
      }
    }
  }
};
</script>
