<template>
  <div id="Step3 " class="p-3">
    <h3 class="title  mb-3">
      {{
        t(
          "At which frequency would you like these payments?"
        )
      }}
    </h3>
    <div class="select is-fullwidth">
      <select v-model="frequency">
        <option
          v-for="{ data, readable } in availableFrequencies"
          :value="data"
          :key="readable"
          >{{ readable }}</option
        >
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step3",
  components: {},
  data() {
    return {
      availableFrequencies: {},

      frequency: "",

      t: this.$store.getters["Translations/getTranslations"](
        this.$parent.$options.name + this.$options.name
      ),
    };
  },
  watch: {
    frequency() {
      this.$emit("update:frequency", this.frequency);
    },
  },
  mounted() {
    this.availableFrequencies = [
      { data: { days: 1, be: '1d', }, readable: this.t("every day") },
      { data: { weeks: 1, be: '1w', }, readable: this.t("every week") },
      { data: { weeks: 2, be: '2w', }, readable: this.t("every other week") },
      { data: { months: 1, be: '1m', }, readable: this.t("every month") },
      { data: { months: 3, be: '3m', }, readable: this.t("every quarter") },
      { data: { months: 6, be: '6m', }, readable: this.t("every six months") },
    ];

    this.frequency = this.availableFrequencies[0].data;
  },
};
</script>

<style></style>
