<template>
  <table class=" mb-0">
    <thead>
      <th>
        {{ t("Id") }}
      </th>
      <th class="has-text-right">
        {{ t("Amount") }}
      </th>
    </thead>
    <tbody>
      <tr v-for="(amount, id) in items" :key="id">
        <td>{{ id }}</td>
        <td class="has-text-right">
          {{ getFormattedNumber(amount) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "ItemsTable",
  data() {
    return {
      t: this.$store.getters["Translations/getTranslations"](
        this.$parent.$options.name + this.$options.name
      ),
    };
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getFormattedNumber(number) {
      return this.$store.getters[`Formatting/getFormattedNumber`](number, this.$parent.$parent.payment_plan_currency);
    },
  }
};
</script>

<style></style>
